<template>
  <div class="decorationBox">
    <div class="selectContent">
      <div class="showType">
        <div :class="['iconBox', nowChooseTypeIndex === index ? 'activeIcon' : '']" v-for="(item, index) in componentList" :key="item.type" @click="nowChooseTypeIndex = index">
          <img :src="nowChooseTypeIndex !== index ? imgH + item.icon : imgH + item.active_icon" alt="" />
          <p>{{ item.name }}</p>
        </div>
      </div>
      <!-- 素材库 -->
      <div class="s_list" v-if="nowChooseTypeIndex === 4">
        <div class="colorArea">
          <div class="colorBox" v-for="item in s_color_lost" :key="item.id" @click="change_s_color(item)">
            <div :style="{ backgroundColor: item.main_color }"></div>
            <div :style="{ backgroundColor: item.secondary_color }"></div>
          </div>
        </div>
        <!-- 素材展示列表 -->
        <div class="show_s">
          <div class="s_components" @click="activeOne(item, 's')" v-for="item in componentList[nowChooseTypeIndex].list" :key="item.style">
            <Style_s_0 v-if="item.style == 's_0'" :config="item.config" :choose_s_color="choose_s_color"></Style_s_0>
            <Style_s_1 v-if="item.style == 's_1'" :config="item.config" :choose_s_color="choose_s_color"></Style_s_1>
            <Style_s_2 v-if="item.style == 's_2'" :config="item.config" :choose_s_color="choose_s_color"></Style_s_2>
            <Style_s_3 v-if="item.style == 's_3'" :config="item.config" :choose_s_color="choose_s_color"></Style_s_3>
            <Style_s_4 v-if="item.style == 's_4'" :config="item.config" :choose_s_color="choose_s_color"></Style_s_4>
            <Style_s_5 v-if="item.style == 's_5'" :config="item.config" :choose_s_color="choose_s_color"></Style_s_5>
            <Style_s_6 v-if="item.style == 's_6'" :config="item.config" :choose_s_color="choose_s_color"></Style_s_6>
            <Style_s_7 v-if="item.style == 's_7'" :config="item.config" :choose_s_color="choose_s_color"></Style_s_7>
          </div>
        </div>
      </div>
      <div class="showList" v-else>
        <div v-for="(item, index) in componentList[nowChooseTypeIndex].list" :key="index" @click="activeOne(item)">
          <img :src="e_yun_admin + item.icon" alt="" />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <!-- 选择组件结束 -->

    <!-- 手机模版显示组件 -->
    <div class="modalPhoneBox">
      <div class="phoneBg">
        <!-- 手机顶部标题栏 -->
        <div :class="['topFixed', chooseId == -1 ? 'topFixedBox' : '', 'mouseBorder']" @click="chooseTop" :style="{ backgroundColor: basicSet.titleBgColor }">
          <div class="pageName" :style="{ color: basicSet.titleColor == 0 ? '#333' : '#fff' }">
            {{ pageTitle }}
          </div>
        </div>
        <!-- 手机中央显示区 -->
        <div ref="modalPhone" class="modalPhone" :style="{ background: basicSet.wholeBgColor }">
          <vuedraggable
            v-model="addComponens"
            v-bind="{
              animation: 200,
              group: 'description',
              disabled: false,
              ghostClass: 'ghost',
              scroll: true,
            }"
            :move="onMove"
          >
            <transition-group>
              <div ref="uicomponents" class="uicomponents" v-for="(item, index1) in addComponens" :key="index1">
                <div :class="['block', chooseId == item.id ? 'border-dotted' : '', 'mouseBorder', index1 === addComponens.length - 1 ? 'lastComponents' : '']" @click="getIndex(item, index1)">
                  <Uicomponents :choose_s_color="choose_s_color" :basicSet="basicSet" :index="index1" :action="action" :componentStyle="item"></Uicomponents>
                  <!-- <i
                    v-show="chooseId == item.id"
                    class="iconfont icon-guanbi"
                    @click="
                      e => {
                        deleteItem(e, index);
                      }
                    "
                    style="color: red; font-size: 20px"
                  ></i> -->
                </div>
              </div>
            </transition-group>
          </vuedraggable>
        </div>
      </div>
    </div>
    <!-- 手机模版显示组件结束 -->

    <!-- 右侧控制栏 -->
    <div class="componentMenuBox">
      <div class="top">
        <span>{{ index == -1 ? '基础设置' : addComponens[index].config.c_name || '素材库组件' }}</span>
        <div s class="del" @click="e => delThis(e)">删除该组件</div>
      </div>
      <ControlIndex
        class="boxbot"
        :pageTitle="pageTitle"
        :basicSet="basicSet"
        :controlIndex="index == -1 ? -1 : addComponens[index].style"
        :controlDefault="controlDefault"
        @getRes="getResult"
        @getSetInfo="getBasicInfo"
      ></ControlIndex>
    </div>
    <!-- 右侧控制栏结束 -->
  </div>
</template>

<script>
import Uicomponents from '../uicomponents/index';
import ControlIndex from '../uicomponents/controlIndex';
import vuedraggable from 'vuedraggable';
import { imgUrlHead, eyun_sass_admin } from '@/util/config.js';
import _ from 'lodash';
// 组件class
import { GenerateComponent } from '@/util/componentsList.js';
// 组件列表
import { componentList } from '../componentList.js';
// 引入所有素材
import Style_s_0 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_0/style_s_0.vue';
import Style_s_1 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_1/style_s_1.vue';
import Style_s_2 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_2/style_s_2.vue';
import Style_s_3 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_3/style_s_3.vue';
import Style_s_4 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_4/style_s_4.vue';
import Style_s_5 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_5/style_s_5.vue';
import Style_s_6 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_6/style_s_6.vue';
import Style_s_7 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_7/style_s_7.vue';
export default {
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
  },
  components: {
    Uicomponents,
    ControlIndex,
    vuedraggable,
    Style_s_0,
    Style_s_1,
    Style_s_2,
    Style_s_3,
    Style_s_4,
    Style_s_5,
    Style_s_6,
    Style_s_7,
  },

  data() {
    return {
      //不能移动的组件Style
      noMoveID: [-1, 0, 13, 17, 18, 22],
      imgH: imgUrlHead + 'diyComponentsImg/',
      e_yun_admin: eyun_sass_admin + 'diyComponentsImg/',
      // 当前选择的组件类型
      nowChooseTypeIndex: 0,
      componentList,
      componensID: 0,
      addComponens: [],
      // 选中第几个元素
      index: -1,
      // 选中元素的id
      chooseId: -1,
      // 控制台默认样式
      controlDefault: {},
      action: {},
      // 基础配置
      basicSet: {
        pageName: '',
        // 2-首页 1-自定义页面
        pageClass: 2,
        // 展示平台
        showPlatform: [2, 3, 4, 5, 6, 7],
        titleBgColor: '#fff',
        // 0-黑色 1-白色
        titleColor: 0,
        wholeBgColor: '#f5f5f5',
      },
      count: 0,
      firstCount: true,
      fourthCount: true,
      lastBaisc: '',
      s_color_lost: [
        {
          id: 1,
          main_color: '#8F91D7',
          secondary_color: '#E1E3F5',
        },
        {
          id: 2,
          main_color: '#FF8637',
          secondary_color: '#FFEDDC',
        },
        {
          id: 3,
          main_color: '#FF6B70',
          secondary_color: '#FFEBE9',
        },
        {
          id: 4,
          main_color: '#25CCC2',
          secondary_color: '#D5FFFD',
        },
        {
          id: 5,
          main_color: '#2084FF',
          secondary_color: '#E5F1FF',
        },
      ],
      choose_s_color: {
        id: 1,
        main_color: '#8F91D7',
        secondary_color: '#E1E3F5',
      },
    };
  },
  methods: {
    // 处理禁止拖拽的元素
    onMove(e, originalEvent) {
      let is_allow_move = this.noMoveID.find(item => item === e.draggedContext.element.style);
      let is_allow_move_end = this.noMoveID.find(item => item === e.relatedContext.element.style);
      if (is_allow_move !== undefined || is_allow_move_end !== undefined) return false;
    },
    // 检索当前页面组件的最大id -> 兼容旧数据
    checkMaxId() {
      if (!this.addComponens.length) return;
      let idArr = [];
      let resultObj = {};
      this.addComponens.map((item, index) => {
        if (!item.config.c_name) {
          item.config.c_name = this.getControlName(item.style);
        }
        idArr.push(item.id);
        if (!resultObj[item.id]) {
          resultObj[item.id] = [index];
        } else {
          resultObj[item.id].push(index);
        }
      });
      let maxId = Math.max(...idArr);
      for (const key in resultObj) {
        if (resultObj[key].length == 1) continue;
        resultObj[key].map(item => {
          this.addComponens[item].id = maxId++;
        });
      }
      this.componensID += maxId;
    },
    // 获取控制台名称
    getControlName(style) {
      let controlName = '';
      switch (style) {
        case 0:
          controlName = '头部背景';
          break;
        case 1:
          controlName = '轮播图';
          break;
        case 2:
          controlName = '搜索框';
          break;
        case 3:
          controlName = '按钮组';
          break;
        case 4:
          controlName = '图片橱窗';
          break;
        case 5:
          controlName = '富文本';
          break;
        case 6:
          controlName = '公告栏';
          break;
        case 7:
          controlName = '视频';
          break;
        case 8:
          controlName = '商品组';
          break;
        case 9:
          controlName = '商户组';
          break;
        case 10:
          controlName = '文章组';
          break;
        case 11:
          controlName = '标题栏';
          break;
        case 12:
          controlName = '辅助线';
          break;
        case 13:
          controlName = '全部商品';
          break;
        case 14:
          controlName = '优惠券';
          break;
        case 15:
          controlName = '直播间';
          break;
        case 16:
          controlName = '拼团';
          break;
        case 17:
          controlName = '视频号';
          break;
        case 18:
          controlName = '客服设置';
          break;
        case 24:
          controlName = '到店支付';
          break;
        case -1:
          controlName = '基础设置';
          break;
        default:
          break;
      }
      return controlName;
    },
    // 点击组件菜单添加组件
    activeOne(item, style) {
      // 检测是否为不能重复组件
      if (this.checkCannotRepeatComponens(item.style)) {
        return;
      }
      this.controlDefault = !style ? GenerateComponent(item.style) : _.cloneDeep(item.config);
      this.componensID += 1;
      console.log(item.style);
      // 组件添加逻辑判断
      if (item.style == 0) {
        // 头部背景只能追加到首位
        this.addComponens.unshift({
          id: this.componensID,
          style: item.style,
          config: this.controlDefault,
        });
        this.index = 0;
      } else if (item.style == 13 || item.style == 22) {
        // 全部商品只能追加到尾部
        this.addComponens.push({
          id: this.componensID,
          style: item.style,
          config: this.controlDefault,
        });
        this.index = this.addComponens.length - 1;
      } else {
        let fixedBottom = [13, 22];
        //是否选中需要固定在底部的组件
        let nowChooseStyle = this.index == -1 ? -1 : this.addComponens[this.index].style;
        let isChoose = null;
        if (nowChooseStyle !== -1) isChoose = fixedBottom.find(data => data == nowChooseStyle);
        // 当选中基础设置时
        if (this.index == -1) {
          if (this.addComponens.length > 0 && this.addComponens[0].style == 0) {
            // 当组件列表有组件并且第一个组件为头部背景时 追加规则->头部背景下一位追加组件 并选中
            this.addComponens.splice(1, 0, {
              id: this.componensID,
              style: item.style,
              config: this.controlDefault,
            });
            this.index = 1;
          } else {
            this.addComponens.splice(0, 0, {
              id: this.componensID,
              style: item.style,
              config: this.controlDefault,
            });
            this.index = 0;
          }
        } else if (this.addComponens.length > 0 && isChoose) {
          // 当组件列表有组件且组件列表最后一项为全部商品且选中全部商品时 追加规则->在全部商品前一位追加组件 并选中
          this.addComponens.splice(this.addComponens.length - 1, 0, {
            id: this.componensID,
            style: item.style,
            config: this.controlDefault,
          });
          this.index = this.addComponens.length - 2;
        } else {
          // 普通情况 追加规则-> 在选中组件后一位按序追加组件 并选中
          this.addComponens.splice(this.index + 1, 0, {
            id: this.componensID,
            style: item.style,
            config: this.controlDefault,
          });
          this.index++;
        }
      }
      this.focusCom(item.style, this.componensID);
    },
    // 检索不能重复添加的组件
    checkCannotRepeatComponens(style) {
      // 传入添加的组件的style -> 即添加组件时获取的index
      let CannotRepeatStyleList = [0, 13, 17, 18, 22];
      // 检测是否同时存在全部商品和全部服务
      if (this.addComponens.length) {
        let last_that = this.addComponens[this.addComponens.length - 1];
        if (style == 13 || style == 22) {
          if (last_that.style == 13 || last_that.style == 22) {
            this.getIndex(last_that, this.addComponens.length - 1, 1);
            this.focusCom(last_that.style, last_that.id);
            this.$message.warning('全部商品组件与全部服务组件不能同时存在');
            return true;
          }
        }
      }

      // 检测添加该组件是否需要检查是否重复
      let isNeedCheck = CannotRepeatStyleList.find(item => item === style);
      if (isNeedCheck !== undefined && this.addComponens.length > 0) {
        let resIndex = 0;
        let res = this.addComponens.find((item, index) => {
          resIndex = index;
          return item.style === style;
        });
        if (res) {
          this.getIndex(res, resIndex, 1);
          this.focusCom(style, res.id);
          this.$message.warning(res.config.c_name + '组件只可以添加一次哟~');
          return true;
        }
      }
      return false;
    },
    // 处理添加组件之后聚焦到组件
    focusCom(style, id) {
      this.chooseId = id;
      this.$nextTick(() => {
        if (!style) {
          this.$refs.modalPhone.scrollTop = 0;
        } else if (style == 13) {
          this.$refs.modalPhone.scrollTop = this.$refs.modalPhone.scrollHeight;
        } else {
          // 这次点击添加的组件的索引
          let nowComIndex = this.addComponens.findIndex(item => item.id === id);
          this.$refs.modalPhone.scrollTop = this.$refs.uicomponents[nowComIndex].offsetTop - 150;
        }
      });
    },
    // 获取控制台修改后的配置
    getResult(config, action, index) {
      if (action) {
        this.action = action;
      }
    },
    // 获取当前选中元素
    getIndex(item, index) {
      this.index = index;
      this.controlDefault = item.config;
      this.chooseId = item.id;
    },
    // 删除组件
    deleteItem(e, index) {
      e.stopPropagation();
      this.addComponens.splice(index, 1);
      this.index = index - 1;
      if (this.index == -1) {
        this.chooseId = -1;
      } else {
        this.controlDefault = this.addComponens[this.index].config;
        this.chooseId = this.addComponens[this.index].id;
      }
    },
    getBasicInfo(val) {
      this.pageName = val;
      this.$emit('getIndexSet', val);
    },
    chooseTop() {
      this.index = -1;
      this.chooseId = -1;
    },
    // 点击删除此组件
    delThis(e) {
      let that = this;
      if (this.index == -1) {
        this.$message({
          message: '此组件不可删除',
          type: 'warning',
        });
        return;
      }
      this.$confirm('确认删除此项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteItem(e, that.index);
        })
        .catch(() => {});
    },
    // 点击切换素材组件的色系
    change_s_color(item) {
      this.$confirm('此操作将更改次页面所有素材库组件的色系!!!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.choose_s_color = item;
        })
        .catch(() => {});
    },
  },
  watch: {
    basicSet: {
      handler(val) {
        if (this.lastBaisc) {
          if (this.lastBaisc !== JSON.stringify(val)) {
            this.$emit('needStorage');
          }
        }

        this.count++;
        if (this.firstCount && this.count == 2) {
          this.count = 0;
          this.firstCount = false;
        }
        sessionStorage.setItem('#_cNum', this.count);
      },
      deep: true,
    },
    addComponens: {
      handler(val) {
        this.count++;
        if (this.firstCount && this.count == 2) {
          this.count = 0;
          this.firstCount = false;
        }
        sessionStorage.setItem('#_cNum', this.count);
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../publicStyle.less);

.decorationBox {
  width: 100%;
  height: calc(100vh - 70px);
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  color: #333333;

  // 选择菜单
  .selectContent {
    flex: 0 0 400px;
    background: #fafafa;
    display: flex;

    .showType {
      flex: 0 0 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      background-color: #fff;

      .iconBox {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 15px 0 12px 0;
        cursor: pointer;

        p {
          white-space: nowrap;
        }
      }

      .iconBox:hover {
        background: rgba(64, 158, 255, 0.1);
      }

      .activeIcon {
        p {
          color: #2680eb !important;
        }
      }
    }

    .showList {
      width: 300px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(auto-fill, 80px);
      padding: 30px;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;

        &:hover {
          background: rgba(64, 158, 255, 0.1);
        }

        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  // 手机模型
  .modalPhoneBox {
    flex: 0 0 1;
    min-width: 409px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
    }

    .phoneBg {
      width: 475px;
      flex-shrink: 0;
      height: 863px;
      background-size: 100% 100%;
      background-image: url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/diyComponentsImg/diy_phone.png);
      position: relative;
      padding: 36px 53px 0 44px;

      .topFixed {
        width: 377px;
        height: 89px;
        background-image: url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/diyComponentsImg/phone_top.png);
        background-size: 100% 100%;
        border-radius: 34px 34px 0 0;
        cursor: pointer;

        &:hover {
          &::after {
            border-radius: 34px 34px 0 0;
          }
        }

        .pageName {
          width: 100%;
          height: 45px;
          position: relative;
          bottom: -44px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .modalPhone {
      width: 377px;
      height: 680px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      overflow-y: auto;
      scroll-behavior: smooth;
      border: 1px solid #eee;
      border-bottom: none;
      border-radius: 0 0 34px 34px;

      & /deep/ .indexBox11 {
        #videoNumberBox,
        #customerService {
          position: absolute;
          bottom: 94px;
          right: 75px;
          z-index: 101;
        }

        #customerService {
          bottom: 182px;
          right: 85px;
        }
      }

      &::-webkit-scrollbar {
        width: 0px;
        height: 5px;
        /**/
      }

      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #f9f9f9;
      }

      &::-webkit-scrollbar-corner {
        background: #204754;
      }

      & > div {
        width: 100%;
        height: 100%;

        span {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .block {
        width: 100%;
        cursor: pointer;
      }
    }
  }

  // 右侧控制栏
  .componentMenuBox {
    flex-shrink: 0;
    width: 400px;
    padding: 15px 20px;
    height: 100%;
    border: 1px solid #e6e6e6;
    overflow: hidden;
    overflow-y: auto;
    background-color: #fff;

    &::-webkit-scrollbar {
      width: 0;
    }

    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      span {
        .title(17px);
      }
    }

    .boxbot {
      font-size: 14px;
      height: calc(100% - 70px);
    }
  }
}

.border-dotted {
  // position: relative;
  // i {
  //   position: absolute;
  //   right: 5px;
  //   top: 2px;
  //   z-index: 100;
  // }
  // &::before {
  //   content: '';
  //   position: absolute;
  //   width: 20px;
  //   height: 20px;
  //   top: 0;
  //   right: -20px;
  //   box-sizing: border-box;
  //   background-color: #fff;
  //   z-index: 99;
  //   cursor: move;
  // }
  & /deep/ .indexBox11 > div {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      box-sizing: border-box;
      border: 2px solid #409eff;
      z-index: 99;
      cursor: move;
    }
  }
}

.topFixedBox {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 377px;
    height: 100%;
    top: 0;
    box-sizing: border-box;
    border: 2px solid #409eff;
    border-radius: 34px 34px 0 0;
    z-index: 99;
    cursor: move;
  }
}

// 鼠标移入组件时显示虚线框
.mouseBorder:hover {
  & /deep/ .indexBox11 > div {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      box-sizing: border-box;
      border: 1px dotted #409eff;
      z-index: 99;
      cursor: move;
    }
  }
}

// 素材库列表
.s_list {
  width: 300px;
  height: 100%;
  flex-shrink: 0;

  .colorArea {
    padding: 24px 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f4f4f4;

    .colorBox {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      cursor: pointer;

      & > div {
        width: 16px;
        height: 32px;
      }

      & > div:first-child {
        border-radius: 16px 0 0 16px;
      }

      & > div:last-child {
        border-radius: 0px 16px 16px 0;
      }
    }
  }

  .show_s {
    height: calc(100% - 81px);
    padding: 0 14px;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }

    .s_components {
      &:hover {
        background-color: #e8f1fb;
      }

      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .s_components > div {
      transform: scale(0.72);
    }
  }
}
</style>

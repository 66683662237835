export const componentList = [
  {
    type: 'Basics',
    name: '基础组件',
    icon: 'Basics.png',
    active_icon: 'Basics_active.png',
    list: [
      { name: '头部背景', icon: 'headerbg.png', style: 0 },
      { name: '轮播图', icon: 'banner.png', style: 1 },
      { name: '搜索框', icon: 'search.png', style: 2 },
      { name: '按钮组', icon: 'btngroup.png', style: 3 },
      { name: '图片橱窗', icon: 'imgwindow.png', style: 4 },
      { name: '富文本', icon: 'richTxt.png', style: 5 },
      { name: '公告栏', icon: 'noticeBar.png', style: 6 },
      { name: '视频', icon: 'video.png', style: 7 },
      { name: '商户组', icon: 'shopgroup.png', style: 9 },
      { name: '文章组', icon: 'txtgroup.png', style: 10 },
      { name: '标题栏', icon: 'titleBar.png', style: 11 },
      { name: '辅助线', icon: 'guide.png', style: 12 },
      { name: '客服组件', icon: 'customerServiceAdmin.png', style: 18 },
      { name: '到店支付', icon: 'pays.png', style: 24 },
      { name: '滚动条', icon: 'gundongIcon.png', style: 25 },
    ],
  },
  {
    type: 'Goods',
    name: '商品组件',
    icon: 'Goods.png',
    active_icon: 'Goods_active.png',
    list: [
      { name: '商品组', icon: 'goodsgroup.png', style: 8 },
      { name: '全部商品', icon: 'allGoods.png', style: 13 },
    ],
  },
  {
    type: 'Marketing',
    name: '营销组件',
    icon: 'Marketing.png',
    active_icon: 'Marketing_active.png',
    list: [
      { name: '优惠券', icon: 'coupon1.png', style: 14 },
      { name: '直播组件', icon: 'liveRoom.png', style: 15 },
      { name: '拼团组件', icon: 'collage.png', style: 16 },
      { name: '视频号组件', icon: 'videoNumber.png', style: 17 },
      { name: '套餐组件', icon: 'setMeal.png', style: 19 },
    ],
  },
  {
    type: 'Service',
    name: '服务组件',
    icon: 'Service.png',
    active_icon: 'Service_active.png',
    list: [
      { name: '服务商品组', icon: 'goodsgroup.png', style: 21 },
      { name: '全部服务商品', icon: 'allGoods.png', style: 22 },
      { name: '卡项组件', icon: 'cardItem.png', style: 20 },
      { name: '商户详情组件', icon: 'shopDetail.png', style: 23 },
    ],
  },
  {
    type: 'Source',
    name: '素材库',
    icon: 'Source.png',
    active_icon: 'Source_active.png',
    list: [
      {
        style: 's_0',
        config: {
          img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/s_default_img_z.png',
          txt_1: '超声波洗牙',
          txt_2: '专业医师',
          price: 88,
          txt_3: '立即购买',
          // 高级设置
          bgColor: '#fff',
          toPage: '',
        },
      },
      {
        style: 's_1',
        config: {
          txt_1: '超值卡项',
          txt_2: 'VALUE CARD ITEM',
          // 高级设置
          fontColor: '#333333',
          toPage: '',
        },
      },
      {
        style: 's_2',
        config: {
          img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/s_default_img.png',
          txt_1: '超声波洗牙',
          txt_2: '立即购买',
          price_1: 1299,
          price_2: 88,
          // 高级设置
          txt_1_Color: '#333333',
          bgColor: '#fff',
          priceColor: '#333333',
          toPage: '',
        },
      },
      {
        style: 's_3',
        config: {
          img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/s_default_img.png',
          txt_1: '超声波洗牙',
          txt_2: '立即购买',
          price_1: 1299,
          price_2: 88,
          // 高级设置
          txt_1_Color: '#333333',
          bgColor: '#fff',
          priceColor: '#333333',
          btnColor: '#8F91D7',
          price_bgColor: '#8F91D7',
          toPage: '',
        },
      },
      {
        style: 's_4',
        config: {
          img_1: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/s_default_img.png',
          img_2: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/s_default_img.png',
          txt_1: '轻医美',
          txt_2: '超声波洗牙服务',
          txt_3: 'CHAOSHENGBOXIYA',
          txt_4: '了解详情',
          txt_5: '轻医美',
          txt_6: '超声波洗牙服务',
          txt_7: 'CHAOSHENGBOXIYA',
          txt_8: '了解详情',
          // 高级设置
          bgColor: '#fff',
          titleColor: '#333333',
          toPage1: '',
          toPage2: '',
        },
      },
      {
        style: 's_5',
        config: {
          img_1: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/s_default_img_z.png',
          img_2: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/s_default_img_z.png',
          txt_1: '超声波洗牙服务',
          txt_2: 'CHAOSHENGBOXIYA',
          txt_3: '了解详情',
          txt_4: '超声波洗牙服务',
          txt_5: 'CHAOSHENGBOXIYA',
          txt_6: '了解详情',
          // 高级设置
          bgColor: '#fff',
          titleColor: '#333333',
          toPage1: '',
          toPage2: '',
        },
      },
      {
        style: 's_6',
        config: {
          img_1: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/s_default_img.png',
          txt_1: '超声波洗牙服务',
          txt_2: '超声波洗牙',
          txt_3: '了解详情',
          // 高级设置
          bgColor: '#fff',
          titleColor: '#333333',
          subTitleColor: '#999',
          btnColor: '#8F91D7',
          toPage: '',
        },
      },
      {
        style: 's_7',
        config: {
          img_1: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/s_default_img_z.png',
          txt_1: '超声波洗牙服务',
          txt_2: '超声波洁牙这是简介这是简介这是简介这是',
          txt_3: '立即预约',
          price_1: 288,
          price_2: 158,
          // 高级设置
          bgColor: '#fff',
          titleColor: '#333333',
          subTitleColor: '#999',
          toPage: '',
        },
      },
    ],
  },
];
